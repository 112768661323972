import React, { FC, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import SEO from 'components/seo'
import { ExternalLink } from 'components/links'
import {
  Box,
  ConfettiStyled,
  Title,
  Description,
  Wrap,
  Block,
  ImageWrapper,
  SpermShield,
  Content,
  BlockTitle,
  BlockText,
} from './styled'

import { parse } from 'query-string'

type QueryResult = {
  image: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    image: file(relativePath: { eq: "sperm-shield.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PurchaseSuccess: FC = () => {
  const data = useStaticQuery<QueryResult>(query)
  const [ showCryoUpsell, setShowCryoUpsell ] = useState(false)
  useEffect(() => {
    const parsedQParams = parse(location.search)
    const customerPurchasedSemenAnalysisParam = parsedQParams['purchased-sa']
    setShowCryoUpsell(Boolean(customerPurchasedSemenAnalysisParam))
  }, [])

  return (
    <>
      <SEO title="Purchase success" />
      <Wrap>
        <Box>
          <ConfettiStyled />
          <Title>
            Congratulations! You&apos;ve successfully ordered your<span translate="no"> Fellow </span>kit.
          </Title>
          <Description center>
            We&apos;ve sent you an email with your receipt. You will receive a
            confirmation email from notifications@meetfellow.com with a tracking
            number once we ship your kit.
          </Description>
          <Description center>
            Questions or concerns? Reach out to{' '}
            <a href="mailto:support@meetfellow.com">support@meetfellow.com</a>
          </Description>
        </Box>
        {showCryoUpsell && (
          <Box >
            <Block>
              <ImageWrapper>
                <SpermShield fluid={data.image.childImageSharp.fluid} />
              </ImageWrapper>
              <Content>
                <BlockTitle>
                  Protect &amp; store your fertility.
                </BlockTitle>
                <BlockText>
                  Male fertility generally decreases with age. You can store your
                  (potentially) healthiest sperm by adding storage to your sample.
                </BlockText>
                <ExternalLink to="https://meetfellow.typeform.com/to/Px7gAZuW">Learn more about storage</ExternalLink>
              </Content>
            </Block>
          </Box>
        )}
      </Wrap>
    </>
  )
}

export default PurchaseSuccess
