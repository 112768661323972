import React, { FC } from 'react'
import Layout from 'components/layout'
import PurchaseSuccess from './purchase-success'

const PurchaseSuccessPage: FC = () => {
  return (
    <Layout invertedHeader headerPosition="fixed">
      <PurchaseSuccess />
    </Layout>
  )
}

export default PurchaseSuccessPage
