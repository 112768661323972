import styled from 'styled-components'
import Img from 'gatsby-image'
import mq from 'styles/mq'
import Confetti from 'components/icons/confetti'
import Button from 'components/button'
import { heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'

export const Wrap = styled.section`
  width: 100%;
  margin: 8rem auto 0 auto;

  ${mq.medium} {
    width: 71.3rem;
    margin: 10.6rem auto;
  }
`

export const Box = styled.section`
  background: #fff;
  padding: 4rem 2.4rem;
  margin-bottom: 4rem;

  ${mq.medium} {
    padding: 4rem;
  }
`

export const ConfettiStyled = styled(Confetti)`
  width: 100%;
  height: auto;
  display: block;

  ${mq.medium} {
    width: 31.48rem;
    margin: auto;
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;

  ${mq.medium} {
    ${heading.l};
  }
`

export const Description = styled.p<{ center?: boolean }>`
  ${paragraph.small};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`

export const LearnMoreButton = styled(Button as any)`
  margin: 2.6rem 0 4.8rem 0;
  display: none;

  ${mq.medium} {
    display: block;
    width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
`
export const LearnMoreButtonMobile = styled(Button as any)`
  margin: 2.6rem 0 4.8rem 0;
  display: block;

  ${mq.medium} {
    display: none;
    width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
`
export const Block = styled.div`
  flex: 1;

  ${mq.medium} {
    display: flex;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${theme.color.border.blue};

    ${mq.medium} {
      border-bottom: none;
      border-right: 0.1rem solid ${theme.color.border.blue};
    }
  }
`

export const ImageWrapper = styled.div`
  width: 12.8rem;
  flex-shrink: 0;
  margin-bottom: 2.5rem;
  color: ${theme.color.accent.secondary};
  font-size: 9rem;

  ${mq.medium} {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
  }
`

export const SpermShield = styled(Img as any)`
  width: 10rem !important;
`

export const Content = styled.div`
  ${mq.medium} {
    padding-left: 3rem;
  }
`

export const BlockTitle = styled.h5`
  ${heading.m};
  margin-bottom: 1.6rem;
  line-height: 1.3;
`

export const BlockText = styled.p`
  ${paragraph.small};
  max-width: 64rem;
`